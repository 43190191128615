.about {
    background-color: #1a1a1a;
    min-height: calc(100vh - 2.8em);
}

.about__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2.8em;
    display: grid;
    align-items: center;
    background-color: #1a1a1a;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
}

.about__title {
    display: flex;
    align-items: center;
    padding: 0.75em 0;
    height: 2.8em;
    margin: 0 1em;
    width: calc(100% - 2em);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px #fff solid;
    color: #fff;
    fill: #fff;
}

.about__title > span {
    font-weight: 500;
}

.about__title > svg {
    margin-left: 0.3em;
    width: 0.5em;
    height: 0.5em;
}

.about__headerMargin {
    margin-top: 1.9em;
}

.about__content {
    color: #fff;
    padding: 1em;
    padding-right: 1.5em;
    white-space: pre-wrap;
    max-width: 40em;
}

.about__content a:any-link {
    text-decoration: underline;
}

.about__content td {
    vertical-align: top;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.45em;
    padding: 0.05em 0;
}

.about__content td {
    min-width: 5em;
}

.about__content p:empty {
    display: none;
}

@media screen and (max-width: 800px) {
    .about__content td {
        min-width: 7em;
    }
}
