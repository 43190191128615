.playContainer {
    position: absolute !important;
}

.playContainer--bordered {
    margin-top: -3em;
    margin-left: -1em;
}

.play {
    cursor: pointer !important;
    padding: 3em;
}
