.header {
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1000;
    height: 2.8em;
    display: grid;
    align-items: center;
    grid-template-columns: auto 3em;
}

.header-title {
    display: flex;
    align-items: center;
    padding: 0.75em 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 500;
}

.header-title > svg {
    margin-left: 0.3em;
    width: 0.5em;
    height: 0.5em;
}

.header-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75em 1em;
    text-align: right;
    cursor: pointer;
}

.header-close > svg {
    width: 0.86em;
    height: 0.86em;
    cursor: pointer;
}
