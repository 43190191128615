.projectContainer {
    position: relative;
    overflow: hidden;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
}

.canvas {
    outline: none;
    height: 100%;
    width: 100%;
    cursor: auto;
}

.slide--left {
    position: absolute;
    z-index: 3;
    top: 2.8em;
    bottom: 0;
    left: 0;
    right: calc(50% + 3em);
    cursor: url(data:image/x-icon;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAOCAYAAAArMezNAAAACXBIWXMAAA9gAAAPYAF6eEWNAAABIUlEQVQ4jWP4//8/Pmz7////DjzyODETA25Qd//+/e0MDAwKOFXgASxYpAQYGBg2NjQ02H348IFhwoQJVzBUEAPQvGL79u3bJ/7+/v9BUvPnz/////9/A1zexYeRXVx47ty55qCgIO6HDx+CBQwMDEBUARnu7WMEmc7AwHBwwoQJdoWFhSiy9vb2GDoIgYSEBBAuBDn7CMjLIK9TA9fX14OCrwAUFD4JCQk7Hjx4YN7Y2EixixUUIIkIFhQg0LF///6iwMBA1o8fP4IFzp8/DwrntQwMDF8wTMAP+jBSxbt3797Y29sjp4oIfLGPC2OTEPj///81UFjl5+eDDJ6CRQ1ZBsPw3Hv37v38////CWobDMKgYLhMjsHIkUc9wMDAAACgLuGaFVA8DQAAAABJRU5ErkJggg==),
        w-resize !important;
}

.slide--right {
    position: absolute;
    z-index: 2;
    top: 2.8em;
    bottom: 0;
    left: calc(50% + 3em);
    right: 0;
    cursor: url(data:image/x-icon;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAOCAYAAAArMezNAAAACXBIWXMAAA9gAAAPYAF6eEWNAAABJklEQVQ4ja1Su0rEQBQ9yvYJ0yoEUouz9kpSWNn4CQlE/AJrwR9IvmCKdFZB8AfWVDZBY2VnArFMoqyNWhyZRSzcZB9hL5xi7txz7p0zFySH4HYZZxvD4rBpmgqA1cvu6rYCnqWUzLJsSvKsq34usSLutbBhGFRKfZO8+c8bAbgAsDf3lMWxOx6Pkec5giAYpWl6GobhixDiAMCbZm6RnLiu6yyU6YiiKFCW5d+FlBJJkkxt2z4HcK3HnmirNwHLsti27SfJfW0FHGftgTsnVkq9m6Z5BeBJWzHE42Pf93fiOJ4dPM9DGIavQogTLTpLDtyKB70Vmh5FEUnebWrdKu1nlmUfJC877gcJmyS/6rquSB711Q0Rdkk+/jbordOft/ZGLA0AP68mInnKL9D5AAAAAElFTkSuQmCC),
        e-resize !important;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.imageContainer {
    position: relative;
    height: 100%;
    width: 100%;
    align-self: center;
    justify-self: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.image {
    height: 100%;
    width: 100%;
    transition: opacity 0.3s ease-in;
    pointer-events: none;
}

.projectFooter {
    position: fixed;
    left: 0;
    width: 100%;
    display: grid;
    height: 2.8em;
    padding: 0.75em 1.1em;
    align-items: center;
    font-size: 0.8em;
    grid-template-columns: 4em 1fr 4em;
    z-index: 2;
    top: calc((var(--vh, 1vh) * 100) - 3.2em);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.caption {
    text-align: center;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 1.2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
