.info {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
}

.info__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2.8em;
    display: grid;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
}

.info__title {
    display: flex;
    align-items: center;
    padding: 0.75em 0;
    height: 2.8em;
    margin: 0 1em;
    width: calc(100% - 2em);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 500;
    border: 0;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: inherit;
    align-self: center;
}

.info__title > svg {
    margin-left: 0.3em;
    width: 0.5em;
    height: 0.5em;
}

.info__content {
    padding: 1em;
    padding-right: 1.5em;
    max-width: 40em;
    white-space: pre-wrap;
}

.info__content a:any-link {
    text-decoration: underline;
}

.info__content p:empty {
    display: none;
}

.social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: -0.5em;
}

.social > li {
    margin-right: 1.5em;
}
