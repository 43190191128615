.overviewContainer {
    background-color: var(--bg-color);
}

.scrollout {
    position: absolute;
    z-index: 2000;
    top: 0.85em;
    left: 50%;
    width: 8em;
    margin-left: -4em;
    text-align: center;
    font-weight: 500;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.overview {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    padding: 0 0.38em;
}

.overview__image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.overview__image {
    margin: 0.38em;
    width: calc(50% - 0.76em);
    height: calc((50vw - 0.76em) * 1.414);
}

.overview__image:first-of-type {
    margin-top: 0;
    width: calc(100% - 0.76em);
    height: calc(100vh - 2.8em - 0.76em);
}

@media screen and (min-width: 650px) {
    .overview__image {
        width: calc(33.333333% - 0.76em);
        height: calc((33.333333vw - 0.76em) * 1.414);
    }
}

@media screen and (min-width: 800px) {
    .overview__image {
        width: calc(25% - 0.76em);
        height: calc((25vw - 0.76em) * 1.414);
    }
}

@media screen and (min-width: 1200px) {
    .overview__image {
        width: calc(20% - 0.76em);
        height: calc((20vw - 0.76em) * 1.414);
    }
}
