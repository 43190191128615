.appbar {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;
    padding: 0.75em 1em;
    height: 2.8em;
    background-color: var(--bg-color);
    display: grid;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    fill: #000;
}

.logoDot {
    font-weight: 500;
    letter-spacing: -1.5px;
}

.appbar-title {
    display: flex;
    align-items: center;
}

.appbar-title span {
    font-weight: 500;
}

.appbar-title svg {
    margin-left: 0.3em;
    width: 0.5em;
    height: 0.5em;
}

.appbarMargin {
    margin-top: 2.8em;
}
