:root {
    --font-size-small: 18px;
    --font-size-medium: 21px;
    --font-size-large: 23px;
    --font-size-big: 25px;
    --font-size-superbig: 27px;
    --bg-color: #f8f8f8;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: var(--font-size-small);
    -webkit-text-size-adjust: 100%;
}

html.noscroll {
    overflow: hidden;
    position: fixed;
}

html,
body {
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}

body.blackBg {
    background-color: #1a1a1a;
    color: #fff;
}

span,
p,
h1,
h2,
h3,
h4,
h5 {
    font-family: 'Roboto', sans-serif;
    hyphens: auto;
    letter-spacing: 0px;
    line-height: 1.2em;
    font-weight: 400;
    hyphens: none;
}

h6,
span,
p {
    line-height: 1.4em;
}

h2 {
    font-size: 2em;
    font-weight: 500;
}

h5 {
    font-size: 0.9em;
    font-weight: 500;
}

h6 {
    font-size: 0.8em;
    font-weight: 400;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    margin: 0;
    padding: 0;
}

table,
tr,
td {
    border: 0;
}

button {
    cursor: pointer;
    outline: none;
}

a:any-link {
    color: inherit;
    text-decoration: none;
}

.centered {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh) * 100);
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.contentMargin {
    margin-top: 1.6em;
}

@media screen and (min-width: 700px) {
    html {
        font-size: var(--font-size-medium);
    }
}

@media screen and (min-width: 1100px) {
    html {
        font-size: var(--font-size-large);
    }
}

@media screen and (min-width: 1500px) {
    html {
        font-size: var(--font-size-big);
    }
}

@media screen and (min-width: 1900px) {
    html {
        font-size: var(--font-size-superbig);
    }
}
